import { useDispatch } from 'react-redux';
import moment from 'moment/moment';

import { changeBulkValidityDates, changeValidityDate } from 'store/attachments';

export const useValidityDate = () => {
  const dispatch = useDispatch();

  const handleChangeValidityDate = async ({ validityDate, attachmentId }) => {
    if (validityDate) {
      await dispatch(
        changeValidityDate({
          validityDate: moment(validityDate).toISOString(true),
          attachmentId
        })
      );
    }
  };

  const handleChangeBulkValidityDates = async ({
    fileList,
    isFromEditor = false,
    validityDates
  }) => {
    const transformData = isFromEditor
      ? fileList
          .filter(
            file =>
              file.validityDateState.validityDateValue &&
              !moment(file.validityDateState.validityDateValue).isSame(
                file.validityDate
              ) &&
              moment(file.validityDateState.validityDateValue).isAfter(
                moment().add(24, 'hours')
              )
          )
          .map(file => ({
            fileId: (file.response || {}).id || file.fileId,
            validityDate: moment(
              file.validityDateState.validityDateValue
            ).toISOString(true)
          }))
      : validityDates.map(({ validityDate, fileId }) => ({
          fileId,
          validityDate: moment(validityDate).toISOString(true)
        }));

    if (transformData.length > 0) {
      await dispatch(changeBulkValidityDates({ data: transformData }));
    }
  };

  const handleChangeValidityDateForNewAttachments = fileList => {
    const transformData = fileList
      .filter(file => file.response && file.response.id)
      .filter(file => file.validityDate)
      .map(file => ({
        fileId: file.response.id,
        validityDate: moment(file.validityDate).toISOString(true)
      }));

    if (transformData.length > 0) {
      dispatch(changeBulkValidityDates({ data: transformData }));
    }
  };

  return {
    handleChangeValidityDate,
    handleChangeBulkValidityDates,
    handleChangeValidityDateForNewAttachments
  };
};

export default useValidityDate;
