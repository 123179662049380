import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  DATE_FORMAT,
  TASK_FIELD_DESCRIPTION,
  TYPE_ASSET
} from 'constants/index';

import {
  FormDatePicker,
  FormEmployeeSelect,
  FormInput,
  FormRelationsSelect,
  FormTagsSelect,
  LabelWithTooltip,
  withoutBubbling
} from 'components/common/hook-form';
import Button from 'components/common/button';
import { convertMessageToString } from 'components/common/comments/converters';
import Typography from 'components/common/typography';
import FormBooksSelect from 'components/common/hook-form/select/books';
import { getAssetPathCategories } from 'components/assets-view/utils';
import { FormNewEditor } from 'components/common/hook-form/markdown';
import useValidityDateFileList from 'components/common/validity-date/use-validity-date-file-list';
import { addValidityDateState } from 'components/common/validity-date/utils/add-validity-date-state';

import { fetchCategoriesLocal } from 'store/assets';

import getFileIds from 'hooks/common/use-file-upload/get-file-ids';
import useUploadingFiles from 'hooks/common/use-file-upload/use-uploading-files';

import styles from './main.module.scss';

export const MainForm = ({
  values = {},
  isEditor,
  isLoading,
  onSubmit,
  isChangeCategory
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['AddAsset', 'Errors', 'Asset', 'Common']);

  const [categories, setCategories] = useState([]);

  const description =
    typeof values.description === 'string'
      ? values.description
      : (values.description || []).filter(el => !el.link);

  const methods = useForm({
    defaultValues: {
      ...values,
      description: {
        description: convertMessageToString(description),
        fileList: (values.fileList || []).map(addValidityDateState)
      },
      responsible: values.responsible
        ? {
            value: values.responsible.id,
            label: values.responsible
          }
        : undefined,
      category: values.category
        ? { value: values.category.id, label: values.category.title }
        : undefined,
      dateEnd: values.dateEnd
        ? moment(values.dateEnd, DATE_FORMAT).toDate()
        : null
    }
  });

  const descriptionValues = methods.watch(TASK_FIELD_DESCRIPTION);
  const isUploadingFiles = useUploadingFiles(descriptionValues.fileList);

  const {
    changeValidityDateStateValue,
    validateValidityDateStateValues
  } = useValidityDateFileList({
    fileList: descriptionValues.fileList,
    onChange: updatedFileList =>
      methods.setValue(TASK_FIELD_DESCRIPTION, {
        ...descriptionValues,
        fileList: updatedFileList
      })
  });

  const transformSubmitedValues = data => {
    const isValidValidityDates = validateValidityDateStateValues();

    if (!isValidValidityDates) {
      return {};
    }

    const pathCategories = getAssetPathCategories({
      allCategories: categories,
      currentCategoryId: data.category.value
    });
    const path = pathCategories.map(c => c.title).join('/');

    return {
      ...data,
      inventoryNumber: data.inventoryNumber || null,
      path,
      category: data.category.value,
      dateEnd: data.dateEnd ? moment(data.dateEnd).format(DATE_FORMAT) : null,
      description: [
        { text: data.description.description },
        ...(data.description.links || [])
      ],
      fileList: (data.description.fileList || []).length
        ? getFileIds(data.description.fileList)
        : [],
      responsible: data.responsible.value,
      attachmentFileList: data.description.fileList || []
    };
  };

  useEffect(() => {
    dispatch(fetchCategoriesLocal()).then(setCategories);

    return setCategories([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '100%'
        }}
        onSubmit={event =>
          withoutBubbling(event, () =>
            methods.handleSubmit(v => onSubmit(transformSubmitedValues(v)))
          )
        }
      >
        {isChangeCategory ? (
          <FormBooksSelect
            name="category"
            valueText={t('ChooseJournal')}
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
            placeholder={t('Search', { ns: 'Asset' })}
            itemProps={{ style: { marginBottom: 24 } }}
          />
        ) : (
          <>
            <FormInput
              label={t('Title')}
              name="title"
              rules={{
                required: t('RequiredField', { ns: 'Errors' }),
                minLength: {
                  value: 2,
                  message: t('MinCheckingName', { ns: 'Errors' })
                },
                maxLength: {
                  value: 250,
                  message: t('ValidateMaxLength', {
                    ns: 'Errors',
                    value: '250'
                  })
                }
              }}
            />

            <FormNewEditor
              name="description"
              label={t('DescOptional')}
              placeholder={t('PleaseDescribeInDetail')}
              actionsDeps={
                isEditor
                  ? {
                      sendCopyToComment: false,
                      assetId: values.id,
                      entityType: TYPE_ASSET
                    }
                  : { entityType: TYPE_ASSET }
              }
              toolbarHidden
              allowAttach
              destination={{
                entityId: values.id,
                entityType: TYPE_ASSET
              }}
              hideValidityDateAction
              validityDateDeps={{ changeValidityDateStateValue }}
              highlightAttachmentsBackground
            />

            <FormBooksSelect
              name="category"
              label={t('Journal')}
              valueText={t('ChooseJournal')}
              rules={{
                required: t('RequiredField', { ns: 'Errors' })
              }}
              placeholder={t('Search', { ns: 'Asset' })}
              itemProps={{ style: { marginBottom: 0 } }}
            />

            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 24,
                marginTop: 16
              }}
            >
              <FormInput
                label={`${t('Number')} ${t('Optional', { ns: 'Common' })}`}
                name="inventoryNumber"
                placeholder={t('Number')}
                rules={{
                  minLength: {
                    value: 1,
                    message: t('MinCheckingOneSymbols', { ns: 'Errors' })
                  },
                  maxLength: {
                    value: 25,
                    message: t('ValidateMaxLength', {
                      ns: 'Errors',
                      value: '25'
                    })
                  }
                }}
              />

              <FormDatePicker
                name="dateEnd"
                label={t('ExpirationDate')}
                minDate={new Date()}
                showTimeSelect={false}
                placeholderText={t('ExpirationDate')}
                wrapperClassname={styles.wrapDatePicker}
              />
            </div>

            <FormEmployeeSelect
              label={
                <LabelWithTooltip
                  label={t('Responsible')}
                  tooltip={t('AssetResponsibleTip')}
                />
              }
              name="responsible"
              rules={{
                required: t('RequiredField', { ns: 'Errors' })
              }}
            />

            <FormInput
              label={t('AddressOptional')}
              name="location"
              placeholder={t('Address')}
              rules={{
                minLength: {
                  value: 2,
                  message: t('MinCheckingName', { ns: 'Errors' })
                },
                maxLength: {
                  value: 250,
                  message: t('ValidateMaxLength', {
                    ns: 'Errors',
                    value: '250'
                  })
                }
              }}
            />

            {!isEditor && (
              <>
                <FormTagsSelect
                  label={t('TagsOptional')}
                  name="tags"
                  itemProps={{
                    style: {
                      marginBottom: 24
                    }
                  }}
                />

                <Typography.Title level={3} style={{ marginBottom: 16 }}>
                  {t('Links')}
                </Typography.Title>

                <FormRelationsSelect name="relations" label={t('LinkType')} />
              </>
            )}
          </>
        )}

        <Button
          htmlType="submit"
          type="primary"
          size="large"
          width="expanded"
          disabled={isUploadingFiles}
          loading={isLoading || isUploadingFiles}
          style={{ margin: 'auto 0 0 auto' }}
        >
          {t('SaveBtn')}{' '}
          {isUploadingFiles && t('FileLoading', { ns: 'Common' })}
        </Button>
      </form>
    </FormProvider>
  );
};

export default MainForm;
