import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';
import moment from 'moment';

import {
  CONTACT_STATUS_VERIFIED,
  LEADS,
  ORDER_STATUS_KINDS,
  OUTGOING,
  TESTID_ORDERS_CREATOR_MODAL_CONTRACTOR_SELECT,
  TESTID_ORDERS_CREATOR_MODAL_DEADLINE_AT_PICKER,
  TESTID_ORDERS_CREATOR_MODAL_PRICE_INPUT,
  TESTID_ORDERS_CREATOR_MODAL_SUBMIT_BUTTON,
  TESTID_ORDERS_CREATOR_MODAL_TITLE_INPUT,
  TYPE_CONTACT
} from 'constants/index';

import {
  FormAttachDocuments,
  FormCurrencySelect,
  FormDatePicker,
  FormInput,
  FormInputNumber,
  FormRelationsSelect
} from 'components/common/hook-form';
import Button from 'components/common/button';
import FormFunnelTypeSelect from 'components/common/hook-form/select/funnel-type';
import Icon from 'components/common/icon';

import { getUserEmployee } from 'store/workspace';

import useMinMaxTime from 'hooks/common/use-min-max-time';

import { getMembers, transformFormSubmittedValues } from '../utils';
import FormCounterpartSelect from '../main/components/counterpart-select';

import styles from './short.module.scss';

const ShortForm = ({
  isCreate,
  isCopy,
  withContractor,
  isLoading,
  defaultValues,
  defaultKind,
  onSubmit
}) => {
  const employee = useSelector(getUserEmployee);

  const { t } = useTranslation([
    'AddOrder',
    'Contacts',
    'AddFile',
    'Errors',
    'Order',
    'Common'
  ]);

  const [relationsVisible, setRelationsVisible] = useState(false);

  const havePrice = defaultValues.state !== LEADS;
  const isRelationsVisible = relationsVisible || defaultValues.relations;

  const methods = useForm({
    defaultValues: {
      title: defaultValues.title,
      fileList: defaultValues.fileList || [],
      currency: {
        label: employee.currency,
        value: employee.currency
      },
      startAt: defaultValues.startAt && new Date(defaultValues.startAt),
      deadlineAt:
        defaultValues.deadlineAt && new Date(defaultValues.deadlineAt),
      price: defaultValues.price || null,
      signatory: defaultValues.signatory
        ? {
            value: defaultValues.signatory.id,
            label: defaultValues.signatory
          }
        : null,
      contractor: defaultValues.contractor
        ? {
            label: {
              ...defaultValues.contractor,
              workspaceTitle:
                (defaultValues.contractor || {}).workspaceTitle ||
                (defaultValues.contractor.company || {}).title
            },
            value: defaultValues.contractor.id
          }
        : null,
      kind: defaultKind
        ? ORDER_STATUS_KINDS.find(x => x.value === defaultKind)
        : ORDER_STATUS_KINDS.find(x => x.value === OUTGOING),
      relations: defaultValues && defaultValues.relations,
      members:
        (defaultValues.contractor && getMembers(defaultValues.contractor)) || []
    }
  });

  const renderServiceNameField = () => (
    <FormInput
      label={t('ServiceName')}
      name="title"
      placeholder={t('EnterName')}
      rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
      data-testid={TESTID_ORDERS_CREATOR_MODAL_TITLE_INPUT}
      itemProps={{ style: { marginBottom: 0 } }}
    />
  );

  const renderDocumentListField = () => (
    <FormAttachDocuments
      label={t('AddFileDrawerTitle', { ns: 'AddFile' })}
      name="fileList"
      buttonProps={{ type: 'link', className: styles.fileButton }}
      itemProps={{ style: { marginBottom: 0 } }}
      actionsDeps={{
        entityType: TYPE_CONTACT,
        entityId: defaultValues.contractorId
      }}
    />
  );

  const [minTimeEnd, maxTimeEnd] = useMinMaxTime({
    endDate: defaultValues.deadlineAt
  });

  const renderDateFields = () => (
    <FormDatePicker
      label={t('OrderDueDate')}
      placeholderText={t('ChooseDate')}
      name="deadlineAt"
      minDate={new Date()}
      minTime={minTimeEnd}
      maxTime={maxTimeEnd}
      wrapperClassname={styles.datePicker}
      dataTestId={TESTID_ORDERS_CREATOR_MODAL_DEADLINE_AT_PICKER}
      itemProps={{ style: { marginBottom: 0 } }}
      rules={{
        required: t('RequiredField', { ns: 'Errors' }),
        validate: value =>
          !value || moment(value).isSameOrAfter(moment())
            ? true
            : t('CannotBeEarlierThanCurrentDate', {
                ns: 'Errors'
              })
      }}
    />
  );

  const renderPriceAndCurrencyFields = () => (
    <>
      <FormInputNumber
        label={t('OrderCost')}
        name="price"
        placeholder="000"
        data-testid={TESTID_ORDERS_CREATOR_MODAL_PRICE_INPUT}
        itemProps={{ style: { marginBottom: 0 } }}
      />
      <FormCurrencySelect
        name="currency"
        label={t('Currency')}
        itemProps={{ style: { marginBottom: 0 } }}
      />
    </>
  );

  const renderContractorFiled = () => (
    <FormCounterpartSelect
      label={t('Contractor')}
      name="contractor"
      rules={{
        required: t('RequiredField', { ns: 'Errors' })
      }}
      params={{
        status: CONTACT_STATUS_VERIFIED
      }}
      dataTestId={TESTID_ORDERS_CREATOR_MODAL_CONTRACTOR_SELECT}
      itemProps={{ style: { marginBottom: 0 } }}
    />
  );

  const renderRelationsField = () => (
    <FormRelationsSelect
      name="relations"
      label={t('Link', { ns: 'AddFile' })}
    />
  );

  return (
    <FormProvider {...methods}>
      <form
        className={styles.formWrapper}
        style={{ gap: !isCreate && !isRelationsVisible && '24px' }}
        onSubmit={methods.handleSubmit(values =>
          onSubmit(
            transformFormSubmittedValues({ values, isShortForm: true, t })
          )
        )}
      >
        <div className={styles.root}>
          {isCreate && (
            <FormFunnelTypeSelect
              label={t('FunnelType', { ns: 'Contacts' })}
              name="kind"
              placeholder={t('EnterFunnelType')}
              itemProps={{ style: { marginBottom: 0 } }}
            />
          )}

          {renderServiceNameField()}
          {renderDocumentListField()}

          {isCreate && (
            <Alert
              type="warning"
              message={t('AttacmentsWillBeSendToContact', { ns: 'Contacts' })}
            />
          )}

          {withContractor && isCreate && renderContractorFiled()}

          {(!isCopy || (isCopy && !havePrice)) && renderDateFields()}

          {isCopy && havePrice && (
            <div className={styles.infoWrap}>
              {renderDateFields()}
              {renderPriceAndCurrencyFields()}
            </div>
          )}

          {isCreate && !isRelationsVisible && (
            <Button
              type="link"
              size="large"
              className={styles.addBtn}
              onClick={() => setRelationsVisible(true)}
            >
              <Icon type="plus" />
              {t('AddLinkToDeal')}
            </Button>
          )}

          {isRelationsVisible && renderRelationsField()}
        </div>

        <div className={styles.submitWrap}>
          <Button
            type="primary"
            size="medium"
            htmlType="submit"
            className={styles.submitBtn}
            loading={isLoading}
            data-testid={TESTID_ORDERS_CREATOR_MODAL_SUBMIT_BUTTON}
          >
            {t(!isCreate ? 'Save' : 'Add', { ns: 'Common' })}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

ShortForm.propTypes = {
  isCreate: PropTypes.bool,
  isEdit: PropTypes.bool,
  isCopy: PropTypes.bool,
  withContractor: PropTypes.bool,
  isLoading: PropTypes.bool,
  defaultValues: PropTypes.shape({}),
  defaultKind: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
};

ShortForm.defaultProps = {
  isLoading: false,
  defaultValues: {},
  defaultKind: undefined,
  isCreate: false,
  isEdit: false,
  isCopy: false,
  withContractor: false
};

export default ShortForm;
