import { createAction } from 'redux-actions';
import moment from 'moment';

import { fetchTagsByEntity } from 'store/tags';

import createActionThunk from '../actions-thunk';
import api from '../../api';
import { getWorkspaceId } from '../workspace';
import {
  getAllTasksLength,
  getItemsPerPage,
  getBacklogFilterTasks,
  getProjectFilterTasks,
  getFilterTasks
} from './selectors';
import mapValue from '../../utils/map-value';
import getFileIds from '../../hooks/common/use-file-upload/get-file-ids';
import {
  STATUSES_EXCLUDE_BACKLOG,
  BACKLOG,
  TYPE_TASK,
  TYPE_TASK_TEMPLATE
} from '../../constants';

const getTasksParams = (
  state,
  access,
  projectId,
  status,
  axiosCancelTokenSource
) => {
  const filter = projectId
    ? getProjectFilterTasks(state)
    : getFilterTasks(state);

  // TODO: поправить момент со статусами (непонятно что происходит)
  const hasFilterStatus =
    Array.isArray(filter.status) && filter.status.length > 0; // если приходят значения из фильтра

  // в случае борда приходит статус STATUSES_EXCLUDE_BACKLOG
  const statusFilter = hasFilterStatus && filter.status.map(mapValue);
  const statusParams = status || statusFilter || STATUSES_EXCLUDE_BACKLOG;
  const authors = filter.authors.map(mapValue);
  const responsibles = filter.responsibles.map(mapValue);
  const asset = (filter.asset || []).map(mapValue);
  const controllers = filter.controllers.map(mapValue);
  const kind = filter.type.map(mapValue);

  const {
    isFavorite,
    sprintIsNull,
    expired,
    subordinates,
    myTasks,
    isAutoCreated,
    tag,
    hideSubtasks
  } = filter;

  const createdDateRange = (filter.createdDateRange || {}).value;
  const startedDateRange = (filter.startedDateRange || {}).value;
  const endedDateRange = (filter.endedDateRange || {}).value;
  const completedDateRange = (filter.completedDateRange || {}).value;

  const hasTags = !!tag.ids.length;
  const tagsCondition = tag.condition.value;
  const tagsIds = hasTags ? tag.ids.map(mapValue) : undefined;

  const project = projectId || filter.projects.map(mapValue);
  const orderStatus = filter.orderStatus.map(mapValue);
  const contacts = filter.contacts.map(mapValue);
  const members = filter.members.map(mapValue);
  const tasks = filter.tasks.map(mapValue);
  const channelChats = filter.channelChats.map(mapValue);
  const sprints = filter.sprints.map(mapValue);
  const ordering = (filter.ordering || {}).key;

  return [
    filter.search,
    kind,
    statusParams,
    authors,
    responsibles,
    controllers,
    subordinates,
    expired,
    createdDateRange,
    startedDateRange,
    endedDateRange,
    completedDateRange,
    project,
    orderStatus,
    contacts,
    sprints,
    sprintIsNull,
    access,
    ordering,
    isFavorite,
    myTasks,
    isAutoCreated,
    hideSubtasks,
    asset,
    tagsCondition,
    tagsIds,
    members,
    tasks,
    channelChats,
    axiosCancelTokenSource
  ];
};

const getBacklogParams = (state, access, project) => {
  let {
    search,
    authors,
    sprint,
    responsibles,
    sprintIsNull,
    hideSubtasks,
    isFavorite,
    subordinates,
    tag,
    members,
    relations,
    createdDateRange
  } = getBacklogFilterTasks(state);
  authors = authors.map(mapValue);
  responsibles = responsibles.map(mapValue);
  members = members.map(mapValue);
  sprint = (sprint || {}).value;

  let { orderStatus, contact, asset, task, channelChat } = relations;
  orderStatus = orderStatus.map(mapValue);
  contact = contact.map(mapValue);
  asset = asset.map(mapValue);
  task = task.map(mapValue);
  channelChat = channelChat.map(mapValue);

  const hasTags = !!tag.ids.length;
  const tagsCondition = tag.condition.value;
  const tagsIds = hasTags ? tag.ids.map(mapValue) : undefined;

  const kind = undefined;
  const status = BACKLOG;
  const expired = undefined;
  const controllers = [];
  const createdDateRangeFilter = (createdDateRange || {}).value;
  const startedDateRange = undefined;
  const endedDateRange = undefined;
  const completedDateRange = undefined;
  const ordering = undefined;
  const myTasks = undefined;
  const isAutoCreated = undefined;

  return [
    search,
    kind,
    status,
    authors,
    responsibles,
    controllers,
    subordinates,
    expired,
    createdDateRangeFilter,
    startedDateRange,
    endedDateRange,
    completedDateRange,
    project,
    orderStatus,
    contact,
    sprint,
    sprintIsNull,
    access,
    ordering,
    isFavorite,
    myTasks,
    isAutoCreated,
    hideSubtasks,
    asset,
    tagsCondition,
    tagsIds,
    members,
    task,
    channelChat
  ];
};

// --TASK-- //
export const fetchTasks = createActionThunk(
  'tasks/fetch-tasks',
  ({
    getState,
    dispatch,
    project,
    access = 'full',
    isBacklog = false,
    limit,
    offset,
    status,
    withoutCancelling,
    axiosCancelTokenSource
  }) => {
    const state = getState();

    const fetch = withoutCancelling
      ? api.tasks.fetchWithoutCancelling
      : api.tasks.fetch;

    const workspaceId = getWorkspaceId(state);
    const resultLimit = limit !== undefined ? limit : getItemsPerPage(state);
    const resultOffset =
      offset !== undefined ? offset : getAllTasksLength(state);

    const params = isBacklog
      ? getBacklogParams(state, access, project)
      : getTasksParams(state, access, project, status, axiosCancelTokenSource);

    return fetch(workspaceId, resultOffset, resultLimit, ...params).then(
      ({ data }) => {
        const taskIds = data.results.map(asset => asset.id);

        if (taskIds) {
          dispatch(
            fetchTagsByEntity({
              entityType: TYPE_TASK,
              entityIds: taskIds
            })
          );
        }

        return {
          results: data.results,
          totalItems: data.count,
          search: params[0] || '',
          isBacklog
        };
      }
    );
  }
);

export const fetchSprintTasks = createActionThunk(
  'tasks/fetch-sprint-tasks',
  ({
    getState,
    authors,
    responsibles,
    taskStatus,
    access,
    myTasks,
    expired,
    subordinates,
    project,
    hideSubtasks,
    tag,
    sprint,
    dispatch,
    offset,
    relations,
    createdDateRange
  }) => {
    const { asset, orderStatus, contact, task } = relations;

    const state = getState();

    const workspaceId = getWorkspaceId(state);
    const limit = getItemsPerPage(state);
    const resultStatus = taskStatus.map(mapValue);
    const authorsResult = authors.map(mapValue);
    const responsibleResult = responsibles.map(mapValue);

    const assetResult = asset.map(mapValue);
    const orderStatusResult = orderStatus.map(mapValue);
    const contactsResult = contact.map(mapValue);
    const tasksResult = task.map(mapValue);

    const taskKind = undefined;

    const controller = [];

    const search = undefined;
    const createdDateRangeFilter = (createdDateRange || {}).value;
    const startedDateRange = undefined;
    const completedDateRange = undefined;

    const sprintIsNull = undefined;
    const ordering = undefined;
    const isFavorite = undefined;
    const isAutoCreated = undefined;

    const hasTags = !!tag.ids.length;
    const tagsCondition = tag.condition.value;
    const tagsIds = hasTags ? tag.ids.map(mapValue) : undefined;
    const members = undefined;
    const channelChats = undefined;
    const endedDateRange = undefined;
    const exclude = undefined;

    return api.tasks
      .fetchWithoutCancelling(
        workspaceId,
        offset,
        limit,
        search,
        taskKind,
        resultStatus,
        authorsResult,
        responsibleResult,
        controller,
        subordinates,
        expired,
        createdDateRangeFilter,
        startedDateRange,
        endedDateRange,
        completedDateRange,
        project,
        orderStatusResult,
        contactsResult,
        sprint,
        sprintIsNull,
        access,
        ordering,
        isFavorite,
        myTasks,
        isAutoCreated,
        hideSubtasks,
        assetResult,
        tagsCondition,
        tagsIds,
        members,
        tasksResult,
        channelChats,
        exclude
      )
      .then(({ data }) => {
        const taskIds = data.results.map(a => a.id);
        if (taskIds) {
          dispatch(
            fetchTagsByEntity({
              entityType: TYPE_TASK,
              entityIds: taskIds
            })
          );
        }

        return {
          results: data.results,
          totalItems: data.count
        };
      });
  }
);

export const fetchTasksLocal = createActionThunk(
  'tasks/fetch-tasks-local',
  ({
    getState,
    search,
    offset,
    status = [],
    access,
    contact,
    limit,
    orderStatus,
    withoutCancelling,
    endedDateRange,
    myTasks,
    expired,
    subordinates,
    project,
    exclude,
    kind
  }) => {
    const state = getState();

    const workspaceId = getWorkspaceId(state);
    const resultLimit = limit || getItemsPerPage(state);
    const resultStatus = status.map(mapValue);

    const taskKind = kind || null;

    const author = [];
    const responsible = [];
    const controller = [];

    const createdDateRange = undefined;
    const startedDateRange = undefined;
    const completedDateRange = undefined;

    const sprint = undefined;
    const sprintIsNull = undefined;
    const ordering = undefined;
    const isFavorite = undefined;
    const isAutoCreated = undefined;
    const hideSubtasks = undefined;
    const asset = undefined;
    const tagsCondition = undefined;
    const tagsIds = undefined;
    const members = undefined;
    const tasks = undefined;
    const channelChats = undefined;

    const fetch = withoutCancelling
      ? api.tasks.fetchWithoutCancelling
      : api.tasks.fetch;

    return fetch(
      workspaceId,
      offset,
      resultLimit,
      search,
      taskKind,
      resultStatus,
      author,
      responsible,
      controller,
      subordinates,
      expired,
      createdDateRange,
      startedDateRange,
      endedDateRange,
      completedDateRange,
      project,
      orderStatus,
      contact,
      sprint,
      sprintIsNull,
      access,
      ordering,
      isFavorite,
      myTasks,
      isAutoCreated,
      hideSubtasks,
      asset,
      tagsCondition,
      tagsIds,
      members,
      tasks,
      channelChats,
      exclude
    ).then(({ data }) => ({
      results: data.results,
      totalItems: data.count,
      search
    }));
  }
);

export const fetchScheduledTaskNextDateLocal = createActionThunk(
  'tasks/fetch-scheduled-task-next-date-local',
  ({ getState, dateEnd, dateStart, freqType, freqInterval, weekdays }) => {
    const state = getState();

    const workspaceId = getWorkspaceId(state);

    return api.taskTemplates
      .fetchScheduledTaskNextDate({
        workspaceId,
        dateEnd,
        dateStart,
        freqType,
        freqInterval,
        weekdays
      })
      .then(({ data }) => data);
  }
);

export const fetchTemplatesLocal = createActionThunk(
  'tasks/fetch-templates',
  ({ getState, dispatch, offset, limit, isScheduler }) => {
    const state = getState();

    const workspaceId = getWorkspaceId(state);

    return api.taskTemplates
      .fetch({
        workspaceId,
        offset,
        limit,
        isScheduler
      })
      .then(({ data }) => {
        const templateIds = data.results.map(asset => asset.id);

        if (templateIds) {
          dispatch(
            fetchTagsByEntity({
              entityType: TYPE_TASK_TEMPLATE,
              entityIds: templateIds
            })
          );
        }

        return {
          results: data.results,
          totalItems: data.count
        };
      });
  }
);

export const fetchTemplateLocal = createActionThunk(
  'tasks/fetch-template',
  ({ getState, id, show400 }) => {
    const state = getState();

    const workspaceId = getWorkspaceId(state);

    return api.taskTemplates
      .fetchOne({ workspaceId, id, show400 })
      .then(({ data }) => data);
  }
);

export const deleteTemplateLocal = createActionThunk(
  'tasks/delete-template',
  ({ getState, id }) => {
    const state = getState();

    const workspaceId = getWorkspaceId(state);

    return api.taskTemplates.delete(workspaceId, id).then(({ data }) => data);
  }
);

export const fetchTask = createActionThunk(
  'tasks/fetch-task',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.tasks.fetchOne(workspaceId, id).then(({ data }) => data);
  }
);

export const fetchTaskLocal = createActionThunk(
  'tasks/fetch-task-local',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.tasks.fetchOne(workspaceId, id).then(({ data }) => data);
  }
);

export const createTask = createActionThunk(
  'tasks/create-task',
  ({ task, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks
      .create(workspaceId, {
        ...task,
        fileList: getFileIds(task.fileList)
      })
      .then(({ data }) => data);
  }
);

export const createTasksInBackground = createActionThunk(
  'tasks/create-tasks-in-background',
  ({ task, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks.backgroundTasks(workspaceId, {
      ...task,
      fileList: getFileIds(task.fileList)
    });
  }
);

export const setTaskFavorite = createActionThunk(
  'tasks/set-task-favorite',
  ({ getState, taskId, isFavorite }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks
      .setFavorite(workspaceId, taskId, isFavorite)
      .then(({ data }) => data);
  }
);

export const createTemplateLocal = createActionThunk(
  'tasks/create-template',
  ({ task, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.taskTemplates
      .create(workspaceId, {
        ...task,
        fileList: getFileIds(task.fileList)
      })
      .then(({ data }) => data);
  }
);

export const shareTemplate = createActionThunk(
  'tasks/share-template',
  ({ id, employeesIds, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.taskTemplates.share(workspaceId, id, employeesIds);
  }
);

export const editTaskFields = createActionThunk(
  'tasks/edit-task',
  ({ value, getState }) => {
    const workspaceId = getWorkspaceId(getState());
    const { description, dateEnd } = value.data;

    return api.tasks
      .update(workspaceId, value.id, {
        ...value.data,
        dateEnd: dateEnd && moment(dateEnd).toISOString(true),
        description
      })
      .then(({ data }) => data);
  }
);

export const partialTaskUpdate = createActionThunk(
  'tasks/partial-update',
  ({ id, values, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.tasks
      .partialUpdate({ workspaceId, id, values })
      .then(({ data }) => data);
  }
);

export const editTaskDates = createActionThunk(
  'tasks/edit-task-dates',
  ({ dates, id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.tasks
      .updateDates({ workspaceId, id, dates })
      .then(({ data }) => data);
  }
);

export const editTemplateFieldsLocal = createActionThunk(
  'tasks/edit-template',
  ({ value, getState }) => {
    const workspaceId = getWorkspaceId(getState());
    const { dateEnd } = value.data;

    return api.taskTemplates
      .update(workspaceId, value.id, {
        ...value.data,
        dateEnd: dateEnd && moment(dateEnd).toISOString(true)
      })
      .then(({ data }) => data);
  }
);

// Разделил удаление на два action, потому что при удаленнии задачи она удалялась из стора до закрытия
// дравера, что приводило к повторной загрузке удаленной задачи и падала ошибка
export const deleteTaskLocal = createActionThunk(
  'tasks/delete-task-local',
  ({ id, projectId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks.delete(workspaceId, id).then(() => ({ id, projectId }));
  }
);

export const deleteTask = createAction('tasks/delete-task');

export const setEstimate = createActionThunk(
  'tasks/set-estimate',
  ({ id, estimation, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks
      .estimate(workspaceId, id, estimation)
      .then(() => ({ id, estimation }));
  }
);

export const setPointEstimate = createActionThunk(
  'tasks/set-point-estimate',
  ({ id, storyPoint, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const estimation = {
      storyPoint,
      isStoryPointEstimation: true
    };

    return api.tasks
      .pointEstimate(workspaceId, id, estimation)
      .then(() => ({ id, storyPoint }));
  }
);

// --ATTACHMENNTS-- //
export const fetchAttachments = createActionThunk(
  'tasks/fetch-attachments',
  ({ id, source, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks
      .fetchAttachments({ workspaceId, id, source })
      .then(({ data }) => data.results);
  }
);

export const changeTaskAttachments = createAction('tasks/change-attachments');

// --CONTACTS-- //
export const fetchContacts = createActionThunk(
  'tasks/fetch-contacts',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.tasks.fetchContacts(workspaceId, id).then(({ data }) => data);
  }
);

export const fetchContact = createActionThunk(
  'tasks/fetch-contact',
  ({ id, contactId, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.tasks
      .fetchContact(workspaceId, id, contactId)
      .then(({ data }) => data);
  }
);

export const deleteContact = createActionThunk(
  'tasks/delete-contact',
  ({ id, contactId, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.tasks
      .deleteContact(workspaceId, id, contactId)
      .then(({ data }) => data);
  }
);

// --RELATIONS-- //
export const fetchRelations = createActionThunk(
  'tasks/fetch-relations',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.tasks
      .fetchRelations(workspaceId, id)
      .then(({ data }) => data.results);
  }
);

export const changeRelations = createActionThunk(
  'tasks/change-relations',
  ({ id, relations, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.tasks.changeRelations({ workspaceId, id, relations });
  }
);

export const fetchTemplateRelationsLocal = createActionThunk(
  'tasks/fetch-template-relations',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.taskTemplates
      .fetchRelations(workspaceId, id)
      .then(({ data }) => data.results);
  }
);

export const deleteTemplateRelationLocal = createActionThunk(
  'tasks/delete-template-relation',
  ({ id, relation, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.taskTemplates
      .deleteRelation(workspaceId, id, relation.id)
      .then(() => ({ id, relationId: relation.id, relation }));
  }
);

export const createTemplateRelationLocal = createActionThunk(
  'tasks/add-template-relation',
  ({ id, relationType, relationId, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.taskTemplates
      .createRelation(workspaceId, id, {
        relationType,
        relationId
      })
      .then(({ data }) => ({ id, data }));
  }
);

// --MEMBERS-- //
export const fetchAssignments = createActionThunk(
  'tasks/fetch-assignments',
  ({ getState, id, directMembersOnly }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks
      .fetchAssignments(workspaceId, id, directMembersOnly)
      .then(({ data }) => data.results);
  }
);

export const changeAssignments = createActionThunk(
  'tasks/change-assignments',
  ({ id, employees, isDelete, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.tasks.assignments({ workspaceId, id, employees, isDelete });
  }
);

// --WORKLOG-- //
export const fetchWorklogList = createActionThunk(
  'tasks/fetch-worklog-list',
  ({ id, getState, offset, limit }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.tasks
      .fetchWorklogList(workspaceId, id, null, offset, limit)
      .then(({ data }) => ({ ...data, id }));
  }
);

export const fetchWorklogLocal = createActionThunk(
  'tasks/fetch-worklog-local',
  ({ id, employeeId, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.tasks
      .fetchWorklogList(workspaceId, id, employeeId)
      .then(({ data }) => data.results);
  }
);

export const createWorklog = createActionThunk(
  'tasks/create-worklog',
  ({ id, value, description, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.tasks
      .createWorklog(workspaceId, id, { value, description })
      .then(({ data }) => ({ taskId: id, ...data }));
  }
);

export const updateWorklog = createActionThunk(
  'tasks/update-worklog',
  ({ id, worklogId, value, parent, description, diff, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.tasks
      .updateWorklog(workspaceId, id, worklogId, { value, description })
      .then(({ data }) => ({ ...data, taskId: id, parent, diff }));
  }
);

export const deleteWorklog = createActionThunk(
  'tasks/delete-worklog',
  ({ id, worklogId, value, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.tasks
      .deleteWorklog(workspaceId, id, worklogId)
      .then(() => ({ taskId: id, worklogId, value }));
  }
);

export const updateStatus = createActionThunk(
  'tasks/update-status',
  ({ id, status, reason, reasonFileList, dateEnd, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.tasks
      .updateStatus(workspaceId, id, status, reason, reasonFileList, dateEnd)
      .then(({ data }) => data);
  }
);

export const changeManager = createActionThunk(
  'tasks/change-manager',
  ({ task, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.tasks
      .changeManager(workspaceId, task.id, { employee: task.employee })
      .then(({ data }) => data);
  }
);

export const fetchSubtaskList = createActionThunk(
  'tasks/fetch-subtask-list',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks.fetchSubtaskList(workspaceId, id).then(({ data }) => ({
      ...data,
      parentId: id
    }));
  }
);

export const fetchHierarchy = createActionThunk(
  'tasks/fetch-hierarchy',
  ({ getState, taskId }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks
      .fetchHierarchy(workspaceId, taskId)
      .then(({ data }) => data.results);
  }
);

export const sendTasksInWork = createActionThunk(
  'tasks/send-tasks-in-work',
  ({ getState, sprintId, tasks }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.sprints
      .sendTasksInWork(workspaceId, sprintId, tasks)
      .then(() => tasks);
  }
);

export const addTasksToSprint = createActionThunk(
  'tasks/add-tasks-to-sprint',
  ({ getState, id, tasks }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.sprints
      .addTasksToSprint(workspaceId, id, tasks)
      .then(({ data }) => data);
  }
);

export const updateSubtasksDates = createActionThunk(
  'tasks/update-subtasks-dates',
  ({ getState, id, dateStart, dateEnd }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks
      .updateSubtasksDates(workspaceId, id)
      .then(() => ({ id, dateStart, dateEnd }));
  }
);

// AGREEMENT
export const agreementAction = createActionThunk(
  'tasks/agreement-action',
  ({ id, action, rejectionReason, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks
      .agreementAction({ workspaceId, id, action, rejectionReason })
      .then(({ data }) => data);
  }
);

export const fetchAgreementSteps = createActionThunk(
  'tasks/fetch-agreement-steps',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks
      .fetchAgreementSteps(workspaceId, id)
      .then(({ data }) => ({ agreementSteps: data, id }));
  }
);

export const fetchTemplateAgreementStepsLocal = createActionThunk(
  'tasks/fetch-template-agreement-steps',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks
      .fetchTemplateAgreementSteps(workspaceId, id)
      .then(({ data }) => data);
  }
);

export const setTaskViewed = createActionThunk(
  'tasks/set-task-viewed',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks.view(workspaceId, id).then(() => ({ id }));
  }
);

export const setTaskViewedApproving = createActionThunk(
  'tasks/set-task-viewed-approving',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks.view(workspaceId, id).then(() => ({ id }));
  }
);

export const fetchAcquaintenceSheet = createActionThunk(
  'tasks/fetch-acquaintance-sheet',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks.fetchAcquaintenceSheet(workspaceId, id);
  }
);

export const uploadAcquaintenceSheetFile = createActionThunk(
  'tasks/upload-acquaintance-sheet-file',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks.uploadAcquaintenceSheetFile(workspaceId, id);
  }
);

export const fetchCoResponsibles = createActionThunk(
  'tasks/fetch-co-responsibles',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks
      .fetchCoResponsibles(workspaceId, id)
      .then(({ data }) => ({ taskId: id, coResponsibles: data }));
  }
);

export const changeController = createActionThunk(
  'tasks/change-controller',
  ({ id, employeeId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks.changeController(workspaceId, id, employeeId);
  }
);

export const changeResponsible = createActionThunk(
  'tasks/change-responsible',
  ({ id, employeeId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks.changeResponsible(workspaceId, id, employeeId);
  }
);

export const convertToSubtask = createActionThunk(
  'tasks/convert-to-subtask',
  ({ taskId, parentId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks.convertToSubtask({ workspaceId, taskId, parentId });
  }
);

export const convertSubtaskToTask = createActionThunk(
  'tasks/convert-subtask-to-task',
  ({ taskId, parentId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tasks.convertSubtaskToTask({ workspaceId, taskId, parentId });
  }
);

export const fetchIssue = createActionThunk(
  'tasks/fetch-issue',
  ({ issueUuid }) =>
    api.tasks.fetchIssue({ issueUuid }).then(({ data }) => data)
);

export const rejectedIssue = createActionThunk(
  'tasks/rejected-issue',
  ({ issueUuid, values }) =>
    api.tasks
      .rejectedIssue({ issueUuid, data: values })
      .then(({ data }) => data)
);

export const acceptedIssue = createActionThunk(
  'tasks/accepted-issue',
  ({ issueUuid }) =>
    api.tasks.acceptedIssue({ issueUuid }).then(({ data }) => data)
);

export const selectTask = createAction('tasks/select', ({ id }) => id);
export const deselectTask = createAction('tasks/deselect', ({ id }) => id);
export const deselectAll = createAction('tasks/deselect-all');
export const changeTaskViewType = createAction('tasks/change-view-type');
export const setTaskData = createAction('tasks/set-task-data');

// filters
export const clearFilter = createAction('tasks/clear-filter');
export const setFilterSearch = createAction('tasks/set-filter-search');
export const setFilterType = createAction('tasks/set-filter-type');
export const setFilterStatus = createAction('tasks/set-filter-status');
export const setFilterFavorite = createAction('tasks/set-filter-favorite');
export const setFilterSprint = createAction('tasks/set-filter-sprint');
export const setFilterAuthor = createAction('tasks/set-filter-author');
export const setFilterResponsible = createAction(
  'tasks/set-filter-responsible'
);
export const setFilterAsset = createAction('tasks/set-filter-asset');
export const setFilterController = createAction('tasks/set-filter-controller');
export const setFilterCompletedDateRange = createAction(
  'tasks/set-filter-completed-date-range'
);
export const setFilterCreatedDateRange = createAction(
  'tasks/set-filter-created-date-range'
);
export const setFilterStartedDateRange = createAction(
  'tasks/set-filter-started-date-range'
);
export const setFilterEndedDateRange = createAction(
  'tasks/set-filter-ended-date-range'
);
export const setFilterSubordinates = createAction(
  'tasks/set-filter-subordinates'
);
export const setFilterMyTasks = createAction('tasks/set-filter-my-tasks');
export const setFilterIsAutoCreated = createAction(
  'tasks/set-filter-is-auto-created'
);
export const setFilterExpired = createAction('tasks/set-filter-expired');
export const setFilterProject = createAction('tasks/set-filter-project');
export const setFilterOrderStatus = createAction(
  'tasks/set-filter-order-status'
);
export const setFilterTasks = createAction('tasks/set-filter-tasks');
export const setFilterContacts = createAction('tasks/set-filter-contacts');
export const setFilterMembers = createAction('tasks/set-filter-members');
export const setFilterSprintIsNull = createAction(
  'tasks/set-filter-sprint-is-null'
);
export const setFilterTag = createAction('tasks/set-filter-tag');
export const setFilterOrdering = createAction('tasks/set-filter-ordering');
export const setFilterHideSubtasks = createAction(
  'tasks/set-filter-hide-subtasks'
);

// backlog filters
export const setBacklogFilterSearch = createAction(
  'tasks/set-backlog-filter-search'
);
export const setBacklogFilterSubordinates = createAction(
  'tasks/set-backlog-filter-subordinates'
);
export const setBacklogFilterMembers = createAction(
  'tasks/set-backlog-filter-members'
);
export const setBacklogFilterAuthors = createAction(
  'tasks/set-backlog-filter-authors'
);
export const setBacklogFilterResponsibles = createAction(
  'tasks/set-backlog-filter-responsibles'
);
export const setBacklogFilterSprint = createAction(
  'tasks/set-backlog-filter-sprint'
);
export const setBacklogFilterHideSubtasks = createAction(
  'tasks/set-backlog-filter-hide-subtasks'
);
export const setBacklogFilterSprintIsNull = createAction(
  'tasks/set-backlog-filter-sprint-is-null'
);
export const setBacklogFilterProject = createAction(
  'tasks/set-backlog-filter-project'
);
export const setBacklogFilterFavorite = createAction(
  'tasks/set-backlog-filter-favorite'
);
export const setBacklogFilterTag = createAction('tasks/set-backlog-filter-tag');
export const setBacklogFilterRelations = createAction(
  'tasks/set-backlog-filter-relations'
);
export const setBacklogFilterCreatedDateRange = createAction(
  'tasks/set-backlog-filter-created-date-range'
);
export const clearBacklogFilter = createAction('tasks/clear-backlog-filter');

// project filters
export const clearProjectFilter = createAction('tasks/clear-project-filter');
export const setProjectFilterTag = createAction('tasks/set-project-filter-tag');
export const setProjectFilterSearch = createAction(
  'tasks/set-project-filter-search'
);
export const setProjectFilterType = createAction(
  'tasks/set-project-filter-type'
);
export const setProjectFilterStatus = createAction(
  'tasks/set-project-filter-status'
);
export const setProjectFilterFavorite = createAction(
  'tasks/set-project-filter-favorite'
);
export const setProjectFilterSprint = createAction(
  'tasks/set-project-filter-sprint'
);
export const setProjectFilterAuthor = createAction(
  'tasks/set-project-filter-author'
);
export const setProjectFilterResponsible = createAction(
  'tasks/set-project-filter-responsible'
);
export const setProjectFilterController = createAction(
  'tasks/set-project-filter-controler'
);
export const setProjectFilterCompletedDateRange = createAction(
  'tasks/set-project-filter-completed-date-range'
);
export const setProjectFilterCreatedDateRange = createAction(
  'tasks/set-project-filter-created-date-range'
);
export const setProjectFilterStartedDateRange = createAction(
  'tasks/set-project-filter-started-date-range'
);
export const setProjectFilterEndedDateRange = createAction(
  'tasks/set-project-filter-ended-date-range'
);
export const setProjectFilterSubordinates = createAction(
  'tasks/set-project-filter-subordinates'
);
export const setProjectFilterExpired = createAction(
  'tasks/set-project-filter-expired'
);
export const setProjectFilterProject = createAction(
  'tasks/set-project-filter-project'
);
export const setProjectFilterOrderStatus = createAction(
  'tasks/set-project-filter-order-status'
);
export const setProjectFilterContacts = createAction(
  'tasks/set-project-filter-contacts'
);
export const setProjectFilterSprintIsNull = createAction(
  'tasks/set-project-filter-sprint-is-null'
);
export const setProjectFilterOrdering = createAction(
  'tasks/set-project-filter-ordering'
);
export const setProjectFilterMyTasks = createAction(
  'tasks/set-project-filter-my-tasks'
);
export const setProjectFilterisAutoCreated = createAction(
  'tasks/set-project-filter-is-auto-created'
);
export const setProjectFilterAsset = createAction(
  'tasks/set-project-filter-asset'
);
export const setProjectFilterMembers = createAction(
  'tasks/set-project-filter-members'
);
export const setProjectFilterTasks = createAction(
  'tasks/set-project-filter-tasks'
);
export const setProjectFilterHideSubtasks = createAction(
  'tasks/set-project-filter-hide-substasks'
);

export const setSprintFilterTag = createAction('tasks/set-sprint-filter-tag');
export const setSprintFilterAuthors = createAction(
  'tasks/set-sprint-filter-authors'
);
export const setSprintFilterResponsible = createAction(
  'tasks/set-sprint-filter-responsible'
);
export const setSprintFilterHideSubtasks = createAction(
  'tasks/set-sprint-filter-hide-substasks'
);
export const setSprintFilterRelations = createAction(
  'tasks/set-sprint-filter-relations'
);
export const setSprintFilterTasksCreatedDateRange = createAction(
  'tasks/set-sprint-filter-tasks-created-date-range'
);
export const setSprintFilterTaskStatus = createAction(
  'tasks/set-sprint-filter-task-status'
);
export const clearSprintTasksFilter = createAction(
  'tasks/clear-sprint-tasks-filter'
);

export const setTasksIsAllLoading = createAction('tasks/set-is-all-loading');

export const clearTasksEntries = createAction('tasks/clear-entries');

export const reorderTasksEntries = createAction('tasks/reorder-tasks-entries');

export const resetReorderingTasksEntries = createAction(
  'tasks/reset-reordering-tasks-entries'
);

export const clearSprintTasksEntries = createAction(
  'tasks/clear-sprint-tasks-entries'
);
