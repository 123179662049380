import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Switch from 'components/common/controls/switch';
import { EmployeeSelect } from 'components/common/controls/custom-select';

import { getIsEditableFile } from 'hooks/common/use-file-upload/types';

import styles from './manage-subscribers.module.scss';

const ManageSubscribers = ({ file, fileList, onChangeFileList }) => {
  const { t } = useTranslation(['FileSubscribers', 'AddFile']);

  const DONE = 'done';
  const isFileLoaded = file.fileId;
  const mimeType = file.mimeType || file.type;
  const key = isFileLoaded ? 'fileId' : file.id ? 'id' : 'uid';

  const handleSwitchChange = value => {
    const updatedFileList = fileList.map(f =>
      f[key] === file[key] ? { ...f, allowSubscriberSelection: value } : f
    );
    onChangeFileList(updatedFileList);
  };

  const handleSubscribersChange = value => {
    const updatedFileList = fileList.map(f =>
      f[key] === file[key] ? { ...f, subscribers: value } : f
    );

    onChangeFileList(updatedFileList);
  };

  const isVisibleManageSubscribers = getIsEditableFile({ mimeType });

  return (
    <>
      {isVisibleManageSubscribers && (
        <div className={styles.root}>
          <Switch
            value={file.allowSubscriberSelection}
            onChange={handleSwitchChange}
            label={t('SubscribeEmployeesToggle', { ns: 'AddFile' })}
            disabled={!isFileLoaded && file.status !== DONE}
          />

          {file.allowSubscriberSelection && (
            <EmployeeSelect
              label={t('Subscribers')}
              isMulti
              value={file.subscribers}
              onChange={handleSubscribersChange}
              params={{
                isPostMethod: true
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

ManageSubscribers.propTypes = {
  file: PropTypes.shape({
    fileId: PropTypes.string,
    id: PropTypes.string,
    uid: PropTypes.string,
    mimeType: PropTypes.string,
    type: PropTypes.string,
    status: PropTypes.string,
    allowSubscriberSelection: PropTypes.bool,
    subscribers: PropTypes.array
  }).isRequired,
  fileList: PropTypes.arrayOf(
    PropTypes.shape({
      fileId: PropTypes.string,
      id: PropTypes.string,
      uid: PropTypes.string,
      mimeType: PropTypes.string,
      type: PropTypes.string,
      status: PropTypes.string,
      allowSubscriberSelection: PropTypes.bool,
      subscribers: PropTypes.array
    })
  ).isRequired,
  onChangeFileList: PropTypes.func.isRequired
};

export default ManageSubscribers;
