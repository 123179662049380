import { createAction } from 'redux-actions';

import { TYPE_ATTACHMENT } from 'constants/index';

import { fetchTagsByEntity } from 'store/tags';

import createActionThunk from '../actions-thunk';
import api from '../../api';
import { getUserEmployee, getWorkspaceId } from '../workspace';
import {
  getCurrentPage,
  getAttachmentsSearchFilter,
  getFilterAttachments,
  getItemsPerPage
} from './selectors';
import mapValue from '../../utils/map-value';
import getOrderingValue from '../../utils/get-ordering-value';
import { getIsEditableFile } from '../../hooks/common/use-file-upload/types';

const transformParams = params => {
  let {
    createdAt = [],
    creators = [],
    projects = [],
    contacts = [],
    orders = [],
    ideas,
    tasks = [],
    sorting = {},
    departments = [],
    asset = [],
    tag = {},
    fileType = [],
    isTrash,
    isMySubscriptions,
    ignoreCache,
    validityDate = [],
    ...otherParams
  } = params;

  createdAt = createdAt.value || [];
  validityDate = validityDate.value || [];
  creators = (creators || []).map(mapValue);
  departments = departments.map(mapValue);
  const relatedProjects = projects.map(mapValue);
  const relatedContacts = contacts.map(mapValue);
  const relatedOrderStatuses = orders.map(mapValue);
  const relatedTasks = tasks.map(mapValue);
  const ordering = getOrderingValue(sorting || {});
  const relatedAsset = (asset || []).map(mapValue);

  const hasTags = tag.ids && !!tag.ids.length;
  const tagsCondition = tag.condition && tag.condition.value;
  const tagsIds = hasTags ? tag.ids.map(mapValue) : undefined;
  const fileExtension = fileType.map(mapValue);

  return {
    ...otherParams,
    ordering,
    createdAt,
    creators,
    departments,
    relatedProjects,
    relatedContacts,
    relatedOrderStatuses,
    relatedTasks,
    relatedAsset,
    tagsCondition,
    tagsIds,
    fileExtension,
    isTrash,
    isMySubscriptions: isTrash ? false : isMySubscriptions,
    ignoreCache,
    validityDate
  };
};

export const fetchAttachments = createActionThunk(
  'attachments/fetch',
  ({ getState, dispatch, isTrash = false, ignoreCache }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const search = getAttachmentsSearchFilter(state);
    const page = getCurrentPage(state);
    const limit = getItemsPerPage(state);
    const offset = limit * (page - 1);
    const filters = getFilterAttachments(state);

    const params = transformParams({
      workspaceId,
      isTrash,
      search,
      offset,
      limit,
      ignoreCache,
      ...filters
    });

    return api.attachments.fetch(params).then(({ data }) => {
      const attachmentIds = data.results.map(attachment => attachment.id);

      if (attachmentIds.length > 0) {
        dispatch(
          fetchTagsByEntity({
            entityType: TYPE_ATTACHMENT,
            entityIds: attachmentIds
          })
        );
      }

      return {
        entries: data.results,
        totalItems: data.count,
        search
      };
    });
  }
);

export const fetchAttachmentsLocal = createActionThunk(
  'attachments/fetch-local',
  ({ getState, dispatch, ...filterParams }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const params = transformParams({
      workspaceId,
      ...filterParams
    });

    return api.attachments.fetch(params).then(({ data }) => {
      const attachmentIds = data.results.map(attachment => attachment.id);

      if (attachmentIds.length > 0) {
        dispatch(
          fetchTagsByEntity({
            entityType: TYPE_ATTACHMENT,
            entityIds: attachmentIds
          })
        );
      }

      return {
        entries: data.results,
        totalItems: data.count
      };
    });
  }
);

export const fetchAttachment = createActionThunk(
  'attachments/fetch-one',
  ({ id, fileId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const resultId = id || fileId;

    return api.attachments.fetchOne({ workspaceId, id: resultId });
  }
);

export const fetchAttachmentLocal = createActionThunk(
  'attachments/fetch-one-local',
  ({ fileId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments.fetchOne({ workspaceId, id: fileId });
  }
);

export const getUrlAttachment = createActionThunk(
  'attachments/get-url',
  ({ fileId, entityType, entityId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments.getUrl({
      workspaceId,
      fileId,
      entityType,
      entityId
    });
  }
);

export const getFileUrls = createActionThunk(
  'attachments/get-urls',
  ({ filesIds, entityType, entityId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments.getUrls({
      workspaceId,
      filesIds,
      entityType,
      entityId
    });
  }
);

export const getUrlAttachmentByType = createActionThunk(
  'attachments/get-url-by-type',
  ({ fileId, mimeType, entityType, entityId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    if (getIsEditableFile({ mimeType })) {
      return api.attachments.view({
        workspaceId,
        fileId,
        entityType,
        entityId
      });
    }

    return api.attachments.getUrl({
      workspaceId,
      fileId,
      entityType,
      entityId
    });
  }
);

export const saveToDiskAttachment = createActionThunk(
  'attachments/save-to-disk',
  ({ fileId, entityType, entityId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments.saveToDisk({
      workspaceId,
      fileId,
      entityType,
      entityId
    });
  }
);

export const makeCopy = createActionThunk(
  'attachments/make-copy',
  ({
    fileId,
    entityType,
    entityId,
    isComment,
    isPrivate,
    fileName,
    getState
  }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments.copy({
      workspaceId,
      fileId,
      fileName,
      isComment,
      isPrivate,
      entityType,
      entityId
    });
  }
);

export const toTrashAttachment = createActionThunk(
  'attachments/to-trash',
  ({ fileId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments.toTrash({ workspaceId, fileId }).then(() => fileId);
  }
);

export const restoreAttachment = createActionThunk(
  'attachments/restore',
  ({ fileId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments.restore({ workspaceId, fileId }).then(() => fileId);
  }
);

export const renameAttachment = createActionThunk(
  'attachments/rename',
  ({ fileId, name, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments
      .rename({ workspaceId, fileId, name })
      .then(({ data }) => data);
  }
);

export const checkSingleRelation = createActionThunk(
  'attachments/check-single-relation',
  ({ fileId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments.checkSingleRelation({ workspaceId, fileId });
  }
);

export const deleteFromEntity = createActionThunk(
  'attachments/delete-from-entity',
  ({ fileId, getState, entityType, entityId, messageUuid }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments.deleteFromEntity({
      workspaceId,
      fileId,
      entityType,
      entityId,
      messageUuid
    });
  }
);

export const deleteAttachment = createActionThunk(
  'attachments/delete',
  ({ fileId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments.delete({ workspaceId, fileId }).then(() => fileId);
  }
);

// Versions actions
export const fetchVersionsAttachment = createActionThunk(
  'attachments/fetch-versions',
  ({ fileId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments
      .fetchVersions({ workspaceId, fileId })
      .then(({ data }) => data);
  }
);

export const getUrlVersionAttachment = createActionThunk(
  'attachments/get-url-version',
  ({ fileId, versionId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments.getUrlVersion({ workspaceId, fileId, versionId });
  }
);

export const viewVersionAttachmentByType = createActionThunk(
  'attachments/get-version-url-by-type',
  ({ fileId, versionId, mimeType, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    if (getIsEditableFile({ mimeType })) {
      return api.attachments.viewVersion({ workspaceId, fileId, versionId });
    }

    return api.attachments.getUrlVersion({ workspaceId, fileId, versionId });
  }
);

export const restoreVersionAttachment = createActionThunk(
  'attachments/restore-version',
  ({ fileId, versionId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments.restoreVersion({ workspaceId, fileId, versionId });
  }
);

export const deleteVersionAttachment = createActionThunk(
  'attachments/delete-version',
  ({ fileId, versionId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments.deleteVersion({ workspaceId, fileId, versionId });
  }
);

export const fetchAgreementSheet = createActionThunk(
  'attachments/fetch-agreement-sheet',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.agreement.fetch(workspaceId, id);
  }
);

export const fetchAgreementSheetFile = createActionThunk(
  'attachments/fetch-agreement-sheet-file',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.agreement.fetchFile(workspaceId, id);
  }
);

export const fetchAccessEmployees = createActionThunk(
  'attachments/fetch-access-employees',
  ({ fileId, params, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments
      .fetchAccessEmployees({ workspaceId, fileId, params })
      .then(({ data }) => data);
  }
);

export const shareAccess = createActionThunk(
  'attachments/share-access',
  ({ fileId, employee, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments
      .shareAccess({ workspaceId, fileId, employee })
      .then(({ data }) => data);
  }
);

export const editAccess = createActionThunk(
  'attachments/edit-access',
  ({ fileId, employeeId, role, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments
      .editAccess({ workspaceId, fileId, employeeId, role })
      .then(({ data }) => data);
  }
);

export const deleteAccess = createActionThunk(
  'attachments/delete-access',
  ({ fileId, employeeId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments.deleteAccess({ workspaceId, fileId, employeeId });
  }
);

export const fetchAccessContacts = createActionThunk(
  'attachments/fetch-access-contacts',
  ({ fileId, params, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments
      .fetchAccessContacts({ workspaceId, fileId, params })
      .then(({ data }) => data);
  }
);

export const shareAccessContact = createActionThunk(
  'attachments/share-access-contact',
  ({ fileId, contact, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments
      .shareAccessContact({ workspaceId, fileId, contact })
      .then(({ data }) => data);
  }
);

export const editAccessContact = createActionThunk(
  'attachments/edit-access-contact',
  ({ fileId, contactId, role, to, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments
      .editAccessContact({ workspaceId, fileId, contactId, role, to })
      .then(({ data }) => data);
  }
);

export const deleteAccessContact = createActionThunk(
  'attachments/delete-access-contact',
  ({ fileId, contactId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.attachments.deleteAccessContact({
      workspaceId,
      fileId,
      contactId
    });
  }
);

export const changeValidityDate = createActionThunk(
  'attachments/change-validity-date',
  ({ getState, attachmentId, validityDate, actionDeps }) => {
    const workspaceId = getWorkspaceId(getState());
    const employee = getUserEmployee(getState());

    return api.attachments
      .changeValidityDate({ workspaceId, attachmentId, validityDate })
      .then(({ data }) => ({
        attachment: data,
        actionDeps: { ...actionDeps, employeeId: employee.id }
      }));
  }
);

export const fetchChatFilesInfo = createActionThunk(
  'attachments/fetch-chat-files-info',
  ({ getState, entityIds, entityId, entityType, messageUuid }) => {
    const workspaceId = getWorkspaceId(getState());
    const employee = getUserEmployee(getState());
    const { id } = employee;

    return api.attachments
      .fetchChatFilesInfo({ workspaceId, entityIds })
      .then(({ data }) => ({
        entityType,
        entityId,
        fileInfoList: data,
        messageUuid,
        employeeId: id
      }));
  }
);

export const changeBulkValidityDates = createActionThunk(
  'attachments/change-bulk-validity-dates',
  ({ getState, data }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.attachments
      .changeBulkValidityDates({ workspaceId, data })
      .then(() => data);
  }
);

export const setFilterSearch = createAction('attachments/set-filter-search');

export const setFilterOrders = createAction('attachments/set-filter-orders');
export const setFilterProjects = createAction(
  'attachments/set-filter-projects'
);
export const setFilterTasks = createAction('attachments/set-filter-tasks');
export const setFilterContacts = createAction(
  'attachments/set-filter-contacts'
);
export const setFilterCreators = createAction(
  'attachments/set-filter-creators'
);
export const setFilterCreatedAt = createAction(
  'attachments/set-filter-created-at'
);
export const setFilterAsset = createAction('attachments/set-filter-asset');
export const setFilterTag = createAction('attachments/set-filter-tag');
export const setFilterFileType = createAction(
  'attachments/set-filter-file-type'
);
export const setFilterMySubscriptions = createAction(
  'attachments/set-filter-my-subscriptions'
);
export const setFilterValidityDate = createAction(
  'attachments/set-filter-validity-date'
);

export const clearFilter = createAction('attachments/clear-filter');
export const setCurrentPage = createAction('attachments/set-current-page');
export const clearError = createAction('attachments/clear-error');
export const setSorting = createAction('attachments/set-sorting');
